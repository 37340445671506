<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="detailInfo.createDate"
      headerTitle="查看公司设立详情"
      :title="`${detailInfo.sponsorName || ''}提交的公司设立审批申请`"
    />
    <div :class="[auditAuthority === 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card name="1" title="基础信息">
        <div class="basics-wrap">
          <my-cell title="申请单号" :value="detailInfo.billNo" />
          <my-cell title="需求事业部/中心" :value="detailInfo.departmentName" />
          <my-cell title="客户简介" :value="detailInfo.customerProfile" />
          <my-cell title="业务体量及利润预估" :value="detailInfo.profitEstimation" />
          <my-cell title="需求背景" :value="detailInfo.demandBackground" />
          <my-cell title="需求主体服务产品">
            <div v-for="item in detailInfo.products" :key="item.productId">
              <span>{{ item.productName }}</span>
            </div>
          </my-cell>  
          <my-cell title="主体落地区域" :value="detailInfo.cityName" />
          <my-cell title="期望主体落地时间" :value="detailInfo.landingDate" />
          <my-cell title="企业主体类型" :value="principalTypeList[detailInfo.principalType - 1]" />
          <my-cell title="主体性质需求" :value="natureRequirementList[detailInfo.natureRequirement - 1]" />
          <my-cell title="主体总/母公司名称" :value="detailInfo.companyName" />
          <my-cell title="社保公积金" :value="socialSecurityHousingFundList[detailInfo.socialSecurityHousingFund]" />
          <my-cell title="主体资质需求">
            <div v-for="item in detailInfo.qualifications" :key="item.qualificationsId">
              <span>{{ item.qualificationsName }}</span>
            </div>
          </my-cell>  
          <my-cell title="纳税人类型" :value="taxpayerTypeList[detailInfo.taxpayerType - 1 ]" />
          <my-cell title="是否为独立核算" :value="detailInfo.independentAccounting == 1 ? '独立核算' : '非独立核算'" />
          <my-cell title="更改核算方式原因" :value="detailInfo.independentReason" />
          <my-cell title="更改纳税人类型原因" :value="detailInfo.reason" />
          <my-cell title="主体经营范围" :value="detailInfo.mainBusinessScope" />
          <my-cell title="开票类目要求" :value="detailInfo.invoicingCategories" />
          <my-cell title="备注" :value="detailInfo.remark" />
          <img class="status-img" :src="statusImg" />
        </div>
      </my-card>

      <my-card name="3" title="附件">
        <my-files-view :fileList="detailInfo.files"/>
      </my-card>

      <approval-process name="4" v-if="id" :billId="id" :module="17" />
    </div>
    <detail-approval-btn v-if="detailInfo.billNo && auditAuthority === 1" :billNo="detailInfo.billNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import { reqCompanyDetail } from '@/api/reimburse'
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);

export default {
  name: "reimburseView",
  data() {
    return {
      id: '', // id
      tabsList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '附件',
          key: 3,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      detailInfo: {},
      statusImg: '',
      auditAuthority: 0,
      principalTypeList: [
        "一般业务公司",
        "产业园管理公司",
        "灵工税源地公司（普通）",
        "灵工税源地公司（KA专属）",
        "外包税源地公司",
        "投资公司"
      ],
      natureRequirementList: [
        "分公司",
        "子公司",
        "与集团公司无股权及人员关联",
        "集团股权关联公司"
      ],
      taxpayerTypeList: [
        "一般纳税人",
        "小规模纳税人"
      ],
      socialSecurityHousingFundList: [
        "不开",
        "社保",
        "灵公保金",
        "社保+公积金"
      ]
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  created() {
    // 查看是否有内容
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    this.auditAuthority = this.$route.query.auditAuthority;
    if (this.id) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
    if(this.status === 2){ // 驳回
      this.statusImg = rejectImg;
    } else if(this.status === 3){ // 通过
      this.statusImg = passImg;
    }
  },
  methods: {
    getDetailInfo() {
      reqCompanyDetail({id: this.id}).then(res => {
        let obj = res.resultData;
        this.detailInfo = obj;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>